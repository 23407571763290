/**
 * Filter Schema of Dapp
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IDappCollectionResourcesHolder} from '@/model/collection/DappCollection'
import {Dapp} from '@/model/resource/Dapp'
import {CategoryCollection} from '@/model/collection/CategoryCollection'
import {NntTagCollection} from '@/model/collection/NntTagCollection'
import {TokenCollection} from '@/model/collection/TokenCollection'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {$} from '@/facade'
import {EnvHelper} from '@/helpers/EnvHelper'

/* TODO: review generated schema */
export class FilterDappSchema extends DefaultSchema
  implements IDappCollectionResourcesHolder {
  collectionBlockchainVersion = new BlockchainVersionCollection().noPagination()
  collectionCategory = new CategoryCollection().noPagination()
  collectionNntTag = new NntTagCollection().noPagination()
  collectionToken = new TokenCollection().noPagination()
  collectionWallet = new WalletCollection().noPagination()

  readonly name = 'FilterDapp'

  get blockchainVersionItems(): BlockchainVersion[] {
    const anyVersion = new BlockchainVersion()
    anyVersion.title = $.t('schema.FilterDapp.anyVersion') as string

    return [anyVersion, ...this.collectionBlockchainVersion.items]
  }

  readonly fieldSet: FieldSet<Dapp> = {
    dappCategory: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionCategory.items,
        multiple: true,
        closeOnSelect: false,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
    dappWallet: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionWallet.items,
        multiple: true,
        closeOnSelect: false,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
    dappBlockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionBlockchainVersion.items,
        hideSelected: false,
        multiple: false,
        closeOnSelect: false,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
        class: {hidden: EnvHelper.VUE_APP_N3_HIDE},
      },
    }),
  }
}
