

















































































































































































import {$} from '@/facade'
import VueI18n from 'vue-i18n'
import {Modal} from '@simpli/vue-modal'
import TranslateResult = VueI18n.TranslateResult
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputCheckbox, InputText} from '@simpli/vue-input'
import {DappCollection} from '@/model/collection/DappCollection'
import {FilterDappSchema} from '@/schema/resource/Dapp/FilterDappSchema'
import {transactionOptions} from '@/components/filters/FilterTransactionView.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Category} from '@/model/resource/Category'
import {Wallet} from '@/model/resource/Wallet'
import ToggleButton from '@/components/misc/ToggleButton.vue'

type SortOption = {
  $id: number
  field: string
  asc: boolean
  $tag: TranslateResult
}

@Component({
  components: {Modal, ToggleButton, InputCheckbox, InputText},
})
export default class FilterDappMobile extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: DappCollection
  @Prop({type: String, required: false, default: ''}) hideFields!: string
  @Prop({type: Boolean, required: true}) isDapp!: boolean

  schema = new FilterDappSchema()
  sorterField: SortOption | null = null
  transactionOptions = transactionOptions
  sortOptions: SortOption[] = [
    {
      $id: 1,
      field: 'transactionCount',
      asc: true,
      $tag: $.t('schema.FilterDapp.txLow'),
    },
    {
      $id: 2,
      field: 'transactionCount',
      asc: false,
      $tag: $.t('schema.FilterDapp.txHigh'),
    },
    {$id: 3, field: 'title', asc: true, $tag: $.t('schema.FilterDapp.nameLow')},
    {
      $id: 4,
      field: 'title',
      asc: false,
      $tag: $.t('schema.FilterDapp.nameHigh'),
    },
  ]

  get isCMS() {
    return this.$route.path.includes('/dapp/list')
  }

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionCategory.queryAsPage(),
      this.collection.resource.collectionNntTag.queryAsPage(),
      this.collection.resource.collectionToken.queryAsPage(),
      this.collection.resource.collectionWallet.queryAsPage(),
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  hasValue(list = [], field: string, value: number) {
    return list.some((item: never) => item[field] === value)
  }

  canShow(field: string): boolean {
    return !this.hideFields.includes(field)
  }

  selectTimeItem(value: string) {
    // TODO: Implement this logic
    // this.collection.date = value
  }

  addCategory(param: Category) {
    const categories = this.collection.dappCategory || []
    if (categories?.includes(param)) {
      this.collection.dappCategory = categories?.filter(
        category => category.idCategoryPk !== category.idCategoryPk
      )
    } else {
      this.collection.dappCategory = [...categories, param]
    }
  }

  addWallet(param: Wallet) {
    const walletList = this.collection.dappWallet || []
    if (walletList?.includes(param)) {
      this.collection.dappWallet = walletList?.filter(
        wallet => wallet.idWalletPk !== wallet.idWalletPk
      )
    } else {
      this.collection.dappWallet = [...walletList, param]
    }
  }

  addVersion(param: number) {
    this.collection.idBlockchainVersionFk = param || null
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }

  doClear() {
    this.collection.dappCategory = []
    this.collection.dappWallet = []
    this.collection.dappBlockchainVersion = null
    // TODO: Implement this logic
    // this.collection.date = null
    // this.collection.max = null
    // this.collection.min = null
    this.collection.inActiveDevelopment = null
    this.doFilter()
  }
}
