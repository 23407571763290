
























import {Component, Prop, Vue} from 'vue-property-decorator'
import {DappCollection} from '@/model/collection/DappCollection'

@Component({})
export default class ToggleButton extends Vue {
  @Prop({type: Object, required: true}) collection!: DappCollection
  @Prop({type: String, required: true}) collectionProp!: string

  value = false

  mounted() {
    this.value =
      this.collectionProp === 'active'
        ? !this.collection.inActiveDevelopment
        : !!this.collection.isDapp
  }

  changeValue() {
    this.value = !this.value

    this.collectionProp === 'active'
      ? this.collection.showInactiveDapps(this.value)
      : this.collection.showIsDapp(this.value)

    this.$emit('active', this.value)
  }
}
